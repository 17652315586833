<template>
  <div class="card">

    <div class="card-title bg-success-800 panel-title"  style="padding-left: 15px;padding-top: 10px;" >
      <h4>
        <span class="font-weight-semibold">Production &nbsp;</span>Delivery
      </h4>
    </div>



    <div class="card-body">

      <div class="row">

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Process</label>
            <select class="form-control select" autofocus="" required="" v-if="trans"  v-model="trans.process.code" @change="loadItem(trans.process.code, trans.ledger.code)"  >
              <option v-for="process in processes" v-bind:value="process.code">
                {{ process.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Delivery Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="trans" v-model="trans.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Order</th>
              <th >Item</th>
              <th style="width:75px; text-align: right;">Qty</th>
              <th style="width:75px; text-align: right;">Weight</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody v-model="trans" v-for="(detail,index) in trans.list" >
              <DetailRow  v-bind:myprocess="trans.process.code"  v-bind:is_credit = "trans.is_cr"   v-bind:myindex ="index"  v-bind:myrow="detail" v-bind:myjobworker="trans.ledger.code"  @onRemoveRow="removeRow(index)" ></DetailRow>
            </tbody>
            <tfoot>
            <tr>
              <td>
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" >
                <!--&#8377;<span style="padding-right: 15px;">{{ indianFormat(rowAmountTotal) }}</span>-->
              </td>

              <td></td>


            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="trans" v-model="trans.remarks" > </textarea>
          </div>
        </div>


        <div class="col-md-6">
          <!--<h3 style="font-size: 48px;color: blue">-->
            <!--&lt;!&ndash;trans.gross_amount&ndash;&gt;-->
            <!--&#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(trans.round_off) ) }}</span>-->
          <!--</h3>-->
        </div>

        <div class="col-md-3 text-right">
          <div class="form-group row">
            <!--<label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>-->
            <!--<div class="col-md-8">-->
              <!--<input id="txtroundoff" type="number" class="form-control text-right" placeholder="Round Off Value" v-if="trans" v-model="trans.round_off" >-->
            <!--</div>-->
          </div>

          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import DetailRow from '@/components/core/ProductionDetailRow.vue'
  import { store } from '@/store/store.js'
  export default {
    name: 'ProductionDeliveryForm',
    components: {
      DetailRow
    },
    store,
    props: {
      mytrans: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    beforeMount () {
      this.trans = this.mytrans;
      this.trans.is_cr="N";
    },
    data () {
      return {
        readonly: false,

        processes:[],
        detailItems:[],
        items : new Map(),
        rowAmountTotal: 0.0,
        trans: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        seriesid: 1004,
      }
    },
    created () {
      let self = this;

      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      let self = this;

      this.loadAll();

      if (self.$data.trans.doc_date === '0001-01-01'){
        self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
        self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
      }

      $('.form-control-select2').select2();
    },
    methods:{
        async loadAll() {
        await this.loadProcesses();
        this.$data.trans = this.mytrans;
      },

      addRow(){
        try {
          let self = this;
          let detail = JSON.parse('{"idx":0,"branch":{"id":"","code":1,"name":"","street":"","city":"","pincode":"","state_code":0,"contact_person":"","telephone":"","email":""},"order_no":1,"work_order":1,"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","article":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"sysobj":false},"unit":{"id":"","code":1,"name":"","digits":0,"sys_obj":false},"rate":0,"qty":0,"qty_txt":"","weight":0,"taxable_amt":0,"gst_rate":0,"tax_amt":0,"igst_amt":0,"sgst_amt":0,"cgst_amt":0,"cess_amt":0,"net_amt":0}');
          detail.idx = self.$data.trans.list.length + 1;
          self.$data.trans.list.push(detail);
          //var myobj = $('table>tbody>tr:last>td:nth-child(2)').children();
          //$(myobj).focus();

        }catch (e) {
          alert(e);
        }
      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;

        let detail = self.$data.trans.list[rowIndex];
        switch (colIndex) {
          case 1:
            detail.item = self.$data.items.get(detail.item.code);
            break;
          case 3:
            self.$data.detail.taxable_amt = detail.qty * detail.rate;
            self.$data.detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
            self.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
            self.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
            self.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
            break;
          case 4:
            detail.taxable_amt = detail.qty * detail.rate;
            detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
            detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
            detail.tax_amt = detail.tax_amt.toFixed(2);
            break;
          case 5:
            detail.taxable_amt = parseFloat(detail.taxable_amt);
            detail.rate = (detail.taxable_amt / detail.qty).toFixed(2);
            detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
            detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
            break;
        }
        self.$data.rowAmountTotal = 0.0;
        self.$data.trans.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
        });
      },

      loadItem(processCode, jobworkerCode){
        this.$data.trans.list=[];
      },
      sumRowAmountTotal() {
        let self = this;

        self.$data.rowAmountTotal = 0.0;
        self.$data.trans.gross_amount = 0.0;
        self.$data.trans.list.forEach(function (detail) {
          self.$data.rowAmountTotal += parseFloat(detail.net_amt);
          self.$data.trans.gross_amount += parseFloat(detail.net_amt);
        });
      },
      clear(){
        let self = this;
        self.$data.trans = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"","type":0,"finyear":0,"series":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
        self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
        self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
        self.$data.trans.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
        self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
      },
      async loadProcesses(){

        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        self.$data.processes = [];
        await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            self.$data.processes = resp.data;
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }

        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        }).finally(function () {

        });

      },
      removeRow(idx){

          if (idx > -1) {
            this.$data.trans.list.splice(idx, 1);
          }
      },
      saveVoucher(){
        let self = this;

        let mytrans =  JSON.parse(JSON.stringify(self.$data.trans));
        mytrans.finyear = store.state.user.finyear;
        mytrans.series = 602;
        mytrans.is_cr = "N";
        mytrans.doc_date = moment(self.$data.trans.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        mytrans.ref_date = moment(self.$data.trans.ref_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        mytrans.eway_date = moment(self.$data.trans.ref_date).format('YYYY-MM-DD') + 'T00:00:00Z';
        mytrans.round_off = parseFloat(self.$data.trans.round_off);
        mytrans.gross_amount = self.$data.rowAmountTotal;
        mytrans.net_amount = mytrans.gross_amount + mytrans.round_off;
        mytrans.ibr = store.store.branch.code;

        mytrans.list.forEach(function (detail){

          detail.order_no = 1;//parseInt(detail.order_no);
          detail.work_order = parseInt(detail.work_order);
          detail.qty_txt = detail.qty.toString();
          detail.qty = parseFloat(detail.qty) * -1;
          detail.weight = parseFloat(detail.weight) * -1;
          detail.unit.code = 1;
          detail.rate = parseFloat(detail.rate);
          detail.taxable_amt = parseFloat(detail.taxable_amt);
          detail.tax_amt = parseFloat(detail.tax_amt);
          detail.net_amt = parseFloat(detail.net_amt);
        });


        const requestOptions = {
          method:  (mytrans.id.length == 0 ? 'POST' : 'PUT'),
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(mytrans)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            swal({
              title: 'Great',
              type: 'success',
              text: resp.message
            });
            self.clear();
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      }
    }
  }
</script>

<style scoped>
  td {
    /*text-align: center;*/
    margin: 0 !important;
    padding:0 !important;
  }
  td input,select {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    /*border: 0 !important;*/
    width: 100% !important;
    height: 35px !important;
    border-radius: 0 !important;
    /*line-height: 1 !important;*/
  }

  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
